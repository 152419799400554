@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .is-dark-background {
    position: relative;
    overflow: hidden;
  }

  .is-dark-background:before {
    content: "";
    position: absolute;
    color: theme('colors.white');
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(/images/testimonial-overlay.png);
    background-repeat: repeat;
    background-position: center;
    opacity: 0.95;
    background-color: theme('colors.primary');
  }

  .header-height {
    @apply h-20;
  }

  .has-brand-border {
    -webkit-box-shadow: 0px 0px 0px 1px theme('colors.primary');
    -moz-box-shadow: 0px 0px 0px 1px theme('colors.primary');
    box-shadow: 0px 0px 0px 1px theme('colors.primary');
  }


  .loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .loader div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: #fff;
  }

  .loader div:nth-child(1) {
    left: 8px;
    animation: loader1 0.6s infinite;
  }

  .loader div:nth-child(2) {
    left: 8px;
    animation: loader2 0.6s infinite;
  }

  .loader div:nth-child(3) {
    left: 32px;
    animation: loader2 0.6s infinite;
  }

  .loader div:nth-child(4) {
    left: 56px;
    animation: loader3 0.6s infinite;
  }

  @keyframes loader1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loader3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes loader2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  .container {
    @apply px-4 md:px-8 lg:max-w-[960px] xl:max-w-[1152px] 2xl:max-w-[1344px];
  }
}